<template>
  <div v-if="data?.messages">
    <div>
      <div class="u-mb-4">
        <p
          v-for="(message, index) in data.messages"
          :key="`message-${index}`"
          class="u-mb-3"
          v-html="message"
        />
      </div>
    </div>
    <div v-if="data.points">
      <hr />
      <div class="u-d-f u-ai-c">
        <div class="img-gauge-container">
          <div class="gauge-container">
            <ClientOnly>
              <ve-progress
                :progress="80"
                :size="113"
                line="square"
                :thickness="15"
                empty-thickness="0"
                color="#1ba2fc"
                empty-color="#FFFFFF"
              />
            </ClientOnly>
          </div>
          <div class="img-container">
            <img
              :src="auth.data.value?.avatar?.url"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
        <div class="detail-point">
          <template v-if="props.data?.incoming">
            <span>
              {{ props.data.points }}
              {{ pluralize('point', props.data.points) }}</span
            >
            à gagner sur votre profil
          </template>
          <template v-else>
            Vous gagnez
            <span>
              {{ props.data?.points }}
              {{ pluralize('point', data.points) }}</span
            >
            sur votre profil
          </template>
        </div>
      </div>
      <hr />
    </div>
    <div
      v-if="props.data?.badges?.length"
      class="badges"
    >
      <p
        v-if="props.data.badges.length <= 1"
        class="u-mb-3"
      >
        Nouveau badge {{ props.data?.incoming ? 'à gagner ' : '' }}:
      </p>
      <p
        v-else
        class="u-mb-3"
      >
        Nouveaux badges {{ props.data?.incoming ? 'à gagner ' : '' }}:
      </p>
      <BadgeItem
        v-for="(badge, index) in props.data.badges"
        :key="`badge-${index}`"
        :badge="badge"
        :large="true"
      />
    </div>
    <div
      v-if="props.data?.ranking"
      class="blue-section ranking"
    >
      <p class="ranking__title">Classement mensuel</p>
      <p v-html="props.data.ranking.subtitle" />
      <div class="u-mt-5">
        <div
          v-for="rank in props.data.ranking.ranks"
          :key="rank?.user?.nickname"
          class="rank"
          :class="{
            'rank--user': auth.data.value?.nickname === rank.user?.nickname,
          }"
        >
          <div class="rank__position">{{ rank.position }}</div>
          <div class="rank__user">
            <div class="rank__picture">
              <img
                v-if="rank.user?.avatar?.url"
                :src="rank.user.avatar.url"
                :alt="rank.user.nickname"
                loading="lazy"
              />
            </div>
            {{ readMore(capitalize(rank.user?.nickname), 25) }}
          </div>
          <div class="rank__points">
            {{ rank.points }} {{ pluralize('point', rank.points) }}
          </div>
          <div class="rank__promo">
            <span> {{ rank.promo_code_value || 0 }}€</span>
            de réduction*
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="props.data?.actions.length"
      class="buttons"
    >
      <ButtonSimple
        v-for="(action, index) in props.data.actions"
        :key="index"
        :label="action.label"
        :outline="!!action.outline"
        @click="clickAction(action)"
      />
    </div>
    <p class="mentions">
      * Récompense provisoire sous réserve de maintenir votre place au
      classement jusqu'à la fin du mois.
    </p>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'
import type { Action, ModalOptions, RewardModalProps } from '~/types'

const props = withDefaults(
  defineProps<{
    data?: ModalOptions | RewardModalProps
  }>(),
  {
    data: undefined,
  },
)

const emit = defineEmits(['openContributedModal'])

const { gtag } = useGtag()
const auth = useAuth()
const vfm = useVfm()

if (props.data?.tracking) {
  gtag(
    props.data.tracking.command,
    props.data.tracking.eventName,
    props.data.tracking.eventParams,
  )
}

const clickAction = (action: Action) => {
  if (action.tracking) {
    gtag(
      action.tracking.command,
      action.tracking.eventName,
      action.tracking.eventParams,
    )
  }

  if (action.type === 'route' && action.route) {
    vfm.closeAll()
    return navigateTo({ path: action.route })
  }

  if (action.type === 'modal' && action.modal) {
    return emit('openContributedModal')
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 1.5rem 0;
  border-bottom: 0;
  opacity: 0.15;
}

.img-gauge-container {
  position: relative;
  width: 11.3rem;
  height: 11.3rem;
  border-right: 50%;

  .gauge-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
  }

  .img-container {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 10.3rem;
    height: 10.3rem;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.detail-point {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;

  span {
    color: $secondaryBlue;
    font-family: 'SST Condensed';
    font-size: 4.6rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
  }
}

.blue-section {
  margin: 0 -2rem;
  padding: 7rem 2rem;

  @media (min-width: 992px) {
    margin: 0 -8rem;
    padding: 9.4rem 8rem 7.4rem;
  }
}

.badges {
  .badge-item + .badge-item {
    margin-top: 2rem;
  }

  + .blue-section {
    margin-top: -3.6rem;
  }
}

.ranking {
  &__title {
    margin-bottom: 0.4rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.rank {
  $root-rank: &;

  display: flex;
  width: calc(100% + 2rem);
  height: 7rem;

  @media (min-width: 992px) {
    width: calc(100% + 8rem);
  }

  &--user {
    background-image: linear-gradient(
      to right,
      rgba($blue, 0) 0%,
      $challengeBackgroundBlue 50%,
      rgba($blue, 0) 100%
    );

    #{$root-rank}__position,
    #{$root-rank}__user,
    #{$root-rank}__points {
      opacity: 1;
    }

    #{$root-rank}__user {
      font-family: 'SST Condensed';
      font-weight: 700;
      border-right-color: rgba(#013d7b, 0.3);
    }

    #{$root-rank}__promo {
      color: $white;
      background-color: $orange;
    }
  }

  &__position,
  &__user,
  &__points {
    opacity: 0.5;
  }

  &__position {
    display: flex;
    flex-basis: 2.5rem;
    align-items: center;
    width: 2.5rem;
    font-family: 'SST Condensed';
    font-weight: 700;
    border-top: 1px solid rgba(#fff, 0.15);

    @media (min-width: 992px) {
      flex-basis: 3.5rem;
      width: 3.5rem;
    }
  }

  &__user {
    display: flex;
    flex-basis: 15rem;
    align-items: center;
    width: 15rem;
    border-top: 1px solid rgba($white, 0.15);
    border-right: 1px solid rgba($white, 0.15);

    @media (min-width: 992px) {
      flex-basis: 22rem;
      width: 22rem;
    }
  }

  &__picture {
    width: 5rem;
    height: 5rem;
    margin-right: 1.4rem;
    overflow: hidden;
    background: rgba($black, 0.2);
    border-radius: 2.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__points {
    display: flex;
    flex-basis: 10.5rem;
    justify-content: center;
    align-items: center;
    width: 10.5rem;
    font-family: 'SST Condensed';
    font-weight: 700;
    border-top: 1px solid rgba(#fff, 0.15);

    @media (min-width: 992px) {
      flex-basis: 12.5rem;
      width: 12.5rem;
    }
  }

  &__promo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1rem;
    color: rgba($white, 0.4);
    font-family: 'SST Condensed';
    line-height: 20px;
    background-color: #b1734c;

    @media (min-width: 992px) {
      padding-left: 2rem;
    }

    span {
      font-size: 2.6rem;
      font-weight: 700;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 4.8rem 0;

  @media (min-width: 992px) {
    flex-direction: row;

    :last-child {
      margin-left: 2.2rem;
    }
  }

  :not(:last-child) {
    margin-bottom: 1.6rem;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

.mentions {
  color: #aaa;
  font-size: 1.2rem;
  line-height: 1.2;
}
</style>
